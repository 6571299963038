import React from "react"
import "resources/style/style.less"
// import { StaticImage } from "gatsby-plugin-image"
import { Col, Typography } from "antd"
import Seo from "../components/seo"
import Layout from "components/layout"

const NotFound = () => {
  return (
    <>
      <Seo title={"Wispay notfound"} />
      <Layout headerVariant="black" headerBackground="blue">
        <div className="base-container-full-screen">
          <div className="wrapper-mockup-404">
            {/* <StaticImage
              alt="Faq welcome"
              placeholder="none"
              src="../resources/img/faq/top-banner-faq.png"
            /> */}
          </div>
          <Col
            xl={6}
            lg={10}
            md={14}
            sm={16}
            xs={20}
            className="text-full-screen"
          >
            <Typography.Text className="text-title">
              404 Not Found.
            </Typography.Text>
          </Col>
        </div>
      </Layout>
    </>
  )
}

export default NotFound
